import { Target } from 'assets/icons'
import styles from './change-detector.module.scss'

export default () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1>
            <Target />
            Change Detector
          </h1>
        </div>
        <p>
          Change detector is moving! Please navigate to{' '}
          <a href='https://rome.ai.northvolt.com/change-detector'> Rome</a> to access the new version.
        </p>
      </div>
    </>
  )
}
